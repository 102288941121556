import useClientMutation from '../../common/useClientMutation';
import { ALERTS_REGISTERED_URL } from '../../consts';

import type {
  NewsletterSignupPersonalOutput,
  NewsletterSignupInput,
} from '@haaretz/s-data-structure-types';

const resourceType = 'newsletter';

export default function useNewsletterSignupPersonal() {
  const searchParams = new URLSearchParams({
    resourceType,
  });

  return useClientMutation<NewsletterSignupPersonalOutput, NewsletterSignupInput>({
    cacheKey: 'NewsletterSignupPersonal',
    url: ALERTS_REGISTERED_URL,
    searchParams,
    fetchOptions: { method: 'GET' },
    clientOptions: {
      useErrorBoundary: false,
      cacheTime: 0,
      retry: false,
    },
  });
}
