'use client';

import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import LayoutContainer from '@haaretz/s-layout-container';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import NewsletterCard from './NewsletterCard';

import type { BiDataOverrides, UserRecommendation } from '@haaretz/s-data-structure-types';
import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  lc: {
    ...merge(
      mq({
        from: 'xxl',
        value: {
          '--contentWidth': `minmax(0, ${space(368)})`,
        },
      })
    ),
  },
  base: {
    gridColumnStart: 'main',
  },
  baseNewslettersVariant: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          gridColumnStart: 'bleed-start',
          gridColumnEnd: 'bleed-end',
          marginInlineEnd: space(3),
          marginInlineStart: space(3),
        },
      }),
      mq({
        from: 'l',
        value: {
          gridColumnStart: 'main-start',
          gridColumnEnd: 'main-end',
        },
      })
    ),
  },
  titleWrapper: {
    marginBottom: space(3),
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          marginBottom: space(4),
        },
      }),
      mq({
        from: 'xl',
        value: {
          marginBottom: space(5),
        },
      })
    ),
  },
  blue: {
    backgroundColor: color('primary1000'),
    height: space(2),
    marginBottom: fork({
      default: space(1),
      hdc: space(2),
    }),
    width: space(20),
    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          height: space(2),
          width: space(23),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          height: space(3),
          width: fork({
            default: space(21),
            hdc: space(24),
          }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          height: space(3),
          marginBottom: space(2),
          width: fork({
            default: space(21),
            hdc: space(36),
          }),
        },
      })
    ),
  },
  listTitle: {
    marginBottom: space(1),
    ...typesetter(3),
    ...merge(
      mq({
        from: 'm',
        until: 'l',
        value: {
          ...fork({
            default: { ...typesetter(2) },
            hdc: { ...typesetter(3) },
          }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(2),
        },
      })
    ),
  },

  li: {
    display: 'flex',
  },
  p: {
    ...typesetter(0),
    ...merge(
      mq({
        from: 'm',
        until: 'xl',
        value: {
          ...fork({
            default: { ...typesetter(0) },
            hdc: { ...typesetter(1) },
          }),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...fork({
            default: { ...typesetter(-1) },
            hdc: { ...typesetter(1) },
          }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...fork({
            default: { ...typesetter(-2) },
            hdc: { ...typesetter(0) },
          }),
        },
      })
    ),
  },

  recommendationsList: {
    display: 'grid',
    marginBottom: fork({
      default: space(4),
      hdc: space(9),
    }),
    rowGap: space(2),
    width: '100%',
  },
  listThankYouVariant: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          columnGap: space(4),
          gridTemplateRows: '1fr 1fr',
          gridTemplateColumns: '1fr 1fr',
          rowGap: space(3),
        },
      }),
      mq({
        from: 'l',
        value: {
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          marginBottom: fork({
            default: space(5),
            hdc: space(9),
          }),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          columnGap: space(3),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          columnGap: space(4),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          columnGap: space(5),
        },
      })
    ),
  },
  listNewslettersVariant: {
    rowGap: space(6),
    gridTemplateColumns: '1fr',
    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      }),
      mq({
        from: 'xl',
        value: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
      }),
      mq({
        from: 'l',
        until: 'xxl',
        value: {
          columnGap: space(4),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          columnGap: space(5),
        },
      })
    ),
    marginBottom: 0,
  },
  hidden: {
    ...merge(mq({ until: 'xl', value: { display: 'none' } })),
  },

  button: {
    justifyContent: 'center',
    textAlign: 'center',
  },
});

export type NewsletterRecommendationListVariant = 'thankYouPage' | 'unsubscribePage';

export type NewsletterRecommendationsListProps = {
  newsLetterRecommendations: UserRecommendation[];
  email?: string;
  styleExtend?: StyleExtend;
} & (
  | ({
      variant: Extract<NewsletterRecommendationListVariant, 'thankYouPage'>;
    } & (
      | {
          biData?: never;
          getBiData: () => BiDataOverrides;
        }
      /** Bi data passed as an argument to biAction */
      | { biData: BiDataOverrides; getBiData?: never }
    ))
  | {
      variant: Extract<NewsletterRecommendationListVariant, 'unsubscribePage'>;
    }
);
export default function NewsletterRecommendationsList({
  newsLetterRecommendations,
  styleExtend = [],
  email,
  ...props
}: NewsletterRecommendationsListProps) {
  const recommendationsToDisplay = newsLetterRecommendations
    ?.filter(newsletter => newsletter.description)
    .slice(0, 4);

  const biAction = useBi();
  const isThankYouPage = props.variant === 'thankYouPage';
  const isUnsubscribePage = props.variant === 'unsubscribePage';

  if (!newsLetterRecommendations?.length) return null;

  return (
    <LayoutContainer as="section" styleExtend={[isThankYouPage && c.lc, ...styleExtend]}>
      <div
        className={s9(c.base, isUnsubscribePage && c.baseNewslettersVariant)}
        data-testid="newsletter-rec-list"
      >
        <div className={s9(c.titleWrapper)}>
          <div className={s9(c.blue)} />
          <h2 className={s9(c.listTitle)} data-testid="newsletter-list-title">
            {fork({
              default: isUnsubscribePage ? 'אולי הדיוורים האלה יעניינו אותך' : 'דיוורים מומלצים',
              hdc: isUnsubscribePage ? 'Recommended Newsletters' : 'Recommended Newsletters',
            })}
          </h2>
          {isThankYouPage ? (
            <p className={s9(c.p)} data-testid="newsletter-list-description">
              {fork({
                default: 'קבלו את התכנים שמעניינים אתכם ישירות לתיבת המייל',
                hdc: 'Make the most of your subscription',
              })}
            </p>
          ) : null}
        </div>
        <ul
          className={s9(
            c.recommendationsList,
            isThankYouPage && c.listThankYouVariant,
            isUnsubscribePage && c.listNewslettersVariant
          )}
          data-testid="newsletter-cards-ul"
        >
          {recommendationsToDisplay.map((recommendation, index) => (
            <li
              key={recommendation.tagNumber}
              className={s9(
                c.li,
                isUnsubscribePage && index === recommendationsToDisplay.length - 1 && c.hidden
              )}
            >
              <NewsletterCard cardData={recommendation} email={email} {...props} />
            </li>
          ))}
        </ul>
        {isThankYouPage ? (
          <div className={s9(c.button)}>
            <Button
              as="a"
              data-testid="for-all-newsletters-btn"
              onClick={() => {
                const biData =
                  typeof props.getBiData === 'function' ? props.getBiData() : props.biData;

                biData &&
                  biAction({
                    ...biData,
                    action_id: 169,
                    feature: 'Link to newsletter page',
                    feature_type: 'Content',
                  });
              }}
              href={fork({
                default:
                  'https://www.haaretz.co.il/newsletter/single?segmentId=0000017e-1ac0-dd6c-a7fe-3ac80b9b0000',
                hdc: 'https://www.haaretz.com/misc/newsletters-page',
                tm: 'https://www.themarker.com/newsletter/single?segmentId=0000017e-1b74-d2ef-a1ff-7ff4f9350000',
              })}
            >
              {fork({
                default: 'לכל הדיוורים',
                hdc: 'To all Newsletters',
              })}
            </Button>
          </div>
        ) : null}
      </div>
    </LayoutContainer>
  );
}
