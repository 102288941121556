'use client';

import useUserRecommendationsPersonal from '@haaretz/s-client-data-hooks/alerts/useUserRecommendationsPersonal';
import { site } from '@haaretz/s-consts';
import NewsletterRecommendationsList from '@haaretz/s-newsletter-recommendations-list';
import useBi from '@haaretz/s-use-bi';
import { useSearchParams } from 'next/navigation';
import React from 'react';

function NewsletterPageMessageClient() {
  const searchParams = useSearchParams();
  const email = searchParams.get('email');
  const resourceName = searchParams.get('resourceName');
  const resourceId = searchParams.get('resourceId');
  const feature = searchParams.get('feature');

  const biAction = useBi();

  const { data } = useUserRecommendationsPersonal({
    site,
    email,
    page: 'unsubscribePage',
  });

  React.useEffect(() => {
    biAction({
      action_id: 9,
      feature: feature ?? '',
      feature_type: 'Content',
      newsletter_email: email ?? '',
      newsletter_segment_name: resourceName ?? '',
      newsletter_segment_id: resourceId ?? '',
    });
  }, [biAction, email, feature, resourceId, resourceName]);

  const recommendations = data?.recommendations;

  return recommendations ? (
    <NewsletterRecommendationsList
      newsLetterRecommendations={recommendations}
      email={email ?? ''}
      variant="unsubscribePage"
    />
  ) : null;
}

export default function NewsletterPageMessageWrapper() {
  return (
    <React.Suspense fallback={null}>
      <NewsletterPageMessageClient />
    </React.Suspense>
  );
}
