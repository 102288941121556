import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';

import useClientQuery from '../../common/useClientQuery';
import { USER_RECOMMENDATIONS } from '../../consts';

import type {
  UserRecommendationsInput,
  UserRecommendationsPersonalOutput,
} from '@haaretz/s-data-structure-types';

const CACHE_TIME = 1000 * 10; // 10 seconds

export default function useUserRecommendationsPersonal({
  email,
  page,
  site,
}: UserRecommendationsInput) {
  const searchParams = new URLSearchParams(
    discardNullableValues({
      email,
      page,
      site,
      bs: 'true',
    })
  );

  return useClientQuery<UserRecommendationsPersonalOutput, UserRecommendationsInput>({
    cacheKey: 'userRecommendations',
    url: USER_RECOMMENDATIONS,
    searchParams,
    clientOptions: {
      useErrorBoundary: false,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      retry: false,
    },
  });
}
