'use client';

import config from '@haaretz/l-config';
import { usePathname, useSearchParams } from 'next/navigation';
import React from 'react';

interface ClusterProviderProps {
  page: number;
  setPage: (nextPage: number) => void;
}

const ClusterProviderContext = React.createContext<ClusterProviderProps | undefined>(undefined);

const disableUrlPagination = config.get('disableUrlPagination');

export default function ClusterProvider({ children }: React.PropsWithChildren) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const currentPage = +(searchParams.get('page') || 0);

  const [page, setPage] = React.useState<number>(currentPage);

  const goToPage = React.useCallback(
    function goToPage(nextPage: number) {
      const params = new URLSearchParams(searchParams.toString());

      params.set('page', String(nextPage));

      window.history.replaceState(null, '', `?${params.toString()}`);
    },
    [searchParams]
  );

  React.useEffect(() => {
    if (disableUrlPagination) return undefined;

    if (page && page > currentPage) {
      const timeout = setTimeout(() => {
        goToPage(page);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [currentPage, goToPage, page, searchParams, pathname]);

  const setNextPage = React.useCallback(function setNextPage(nextPage: number) {
    setPage(prevPage => {
      if (nextPage > prevPage) return nextPage;

      return prevPage;
    });
  }, []);

  const value = React.useMemo(
    () => ({
      page,
      setPage: setNextPage,
    }),
    [page, setNextPage]
  );

  return <ClusterProviderContext value={value}>{children}</ClusterProviderContext>;
}

export function useCluster() {
  const context = React.use(ClusterProviderContext);

  if (context === undefined) {
    throw new Error('useCluster must be used within a ClusterProvider');
  }

  return context;
}
