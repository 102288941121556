'use client';

import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import Card from '@haaretz/s-card';
import useNewsletterSignupPersonal from '@haaretz/s-client-data-hooks/alerts/useNewsletterSignupPersonal';
import useUserAlertsRegistration from '@haaretz/s-client-data-hooks/alerts/useUserAlertsRegistration';
import FormfieldDescription from '@haaretz/s-formfield-description';
import HtzImage from '@haaretz/s-htz-image';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import type { NewsletterRecommendationListVariant } from './NewsletterRecommendationsList';
import type { ButtonProps, ButtonState } from '@haaretz/s-button';
import type { BiDataOverrides, UserRecommendation } from '@haaretz/s-data-structure-types';

const c = s9.create({
  base: {
    display: 'grid',
    width: '100%',
    rowGap: space(2),
    gridTemplateRows: 'min-content 1fr auto',
    padding: space(3),
  },
  baseThankYouVariant: {
    gridTemplateRows: '1fr repeat(2, auto)',

    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          rowGap: space(1),
        },
      })
    ),
  },
  baseUnsubscribeVariant: {
    rowGap: space(3),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          rowGap: space(4),
          padding: space(4),
        },
      }),
      mq({
        from: 'l',
        value: {
          rowGap: space(2),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          paddingTop: space(3),
          paddingBottom: space(3),
          paddingInlineStart: space(2),
          paddingInlineEnd: space(2),
        },
      })
    ),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyItems: 'flex-start',
  },
  headerUnsubscribeVariant: {
    ...merge(mq({ from: 'l', value: { alignItems: 'flex-start' } })),
  },
  img: {
    borderRadius: space(1),
    height: 'var(--size)',
    width: 'var(--size)',
  },
  imgThankYouVariant: {
    '--size': space(14),
    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { '--size': space(16) } }),
      mq({ from: 'xxl', value: { '--size': space(17) } })
    ),
  },
  imgUnsubscribeVariant: {
    '--size': space(18),
  },
  titlesWrapper: {
    marginInlineStart: space(2),
    ...mq({
      from: 'xl',
      value: {
        marginInlineStart: space(3),
      },
    }),
  },
  titlesWrapperUnsubscribeVariant: {
    marginInlineStart: space(3),
  },
  subjectTitleThankYouVariant: {
    color: color('primary1000'),
    ...merge(
      fork({
        default: { ...typesetter(2, { lines: 6 }) },
        hdc: { ...typesetter(1, { lines: 6 }) },
      }),
      mq({
        from: 's',
        until: 'l',
        value: fork({
          default: { ...typesetter(1, { lines: 6 }) },
          hdc: { ...typesetter(1, { lines: 6 }) },
        }),
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: fork({
          default: { ...typesetter(0, { lines: 5 }) },
          hdc: { ...typesetter(0, { lines: 6 }) },
        }),
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(0, { lines: 6 }),
        },
      }),
      mq({
        from: 'xxl',
        value: fork({
          default: { ...typesetter(1, { lines: 6 }) },
          hdc: { ...typesetter(0, { lines: 6 }) },
        }),
      })
    ),
  },
  subjectTitleUnsubscribeVariant: {
    ...typesetter(1),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  mailingType: {
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
  },
  mailingTypeUnsubscribeVariant: {
    ...typesetter(-2),
    color: color('neutral900'),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...fork({
            default: typesetter(-2),
            hdc: typesetter(-1),
          }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-4),
        },
      })
    ),
  },
  mailingTypeThankYouVariant: {
    ...typesetter(-1),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...fork({
            default: typesetter(-2),
            hdc: typesetter(-1),
          }),
        },
      })
    ),
  },
  premiumMailing: {
    backgroundColor: color('tertiary700'),
    color: color('selectedText'),
    display: 'inline',
    paddingInlineStart: space(1),
    paddingInlineEnd: space(1),
    WebkitBoxDecorationBreak: 'clone',
  },
  description: {
    display: '-webkit-box',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
  },
  descriptionThankYouVariant: {
    ...typesetter(-1),
    WebkitLineClamp: fork({
      default: '3',
      hdc: '4',
    }),
    ...merge(
      mq({
        from: 's',
        until: 'm',
        value: {
          maxHeight: fork({
            default: space(15),
            hdc: space(20),
          }),
        },
      }),
      mq({
        from: 'm',
        until: 'l',
        value: {
          ...typesetter(0),
          maxHeight: fork({
            default: space(20),
            hdc: space(23),
          }),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          maxHeight: fork({
            default: space(15),
            hdc: space(20),
          }),
        },
      }),

      mq({
        from: 'xl',
        until: 'xxl',
        value: fork({
          default: {
            maxHeight: space(15),
            ...typesetter(-2),
          },
          hdc: {
            maxHeight: space(25),
            ...typesetter(-1),
          },
        }),
      }),
      mq({
        from: 'xxl',
        value: {
          maxHeight: fork({
            default: space(20),
            hdc: space(28),
          }),
        },
      })
    ),
  },
  descriptionUnsubscribeVariant: {
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          paddingBottom: space(4),
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingBottom: space(0),
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  icon: {
    marginInlineEnd: space(1),
  },
  slideAnimationInSuccess: {
    animationName: s9.keyframes({
      '0%': {
        insetInlineStart: '30%',
        opacity: 0,
      },
      '30%': {
        insetInlineStart: space(3),
        opacity: 0.8,
      },
      '80%': {
        opacity: 1,
      },
      '100%': {
        visibility: 'hidden',
        opacity: 0,
      },
    }),
    animationDelay: '150ms',
    animationDirection: 'forward',
    animationDuration: '2.5s',
  },
  successDiv: {
    alignItems: 'center',
    alignSelf: 'flex-end',
    color: color('quaternary700'),
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    display: 'flex',
    fontWeight: 750,
    justifyContent: 'flex-start',
    marginTop: space(2),
    opacity: 0,
    position: 'absolute',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    ...typesetter(-2),
  },
  slideOutTransitionButton: {
    transitionProperty: 'inset-inline-start, opacity, visibility',
    transitionDuration: '1.5s, 0.5s, 0.5s',
    visibility: 'hidden',
    opacity: 0,
    insetInlineStart: '-200%',
    transitionTimingFunction: 'ease-in-out',
  },
  formfield: {
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    opacity: 0,
    ...merge(
      fork({
        default: { ...typesetter(-2) },
        hdc: { ...typesetter(-1) },
      }),
      mq({
        from: 'l',
        until: 'xxl',
        value: fork({
          default: { ...typesetter(-2) },
          hdc: { ...typesetter(-2, { lines: 4 }) },
        }),
      })
    ),
    maxHeight: 0,
    transitionDelay: '0s, 1.5s,',
    transitionDuration: '1s',
    transitionProperty: 'opacity, max-height',
  },
  formfieldTransition: {
    transitionDelay: '2.5s',
    opacity: 1,
    maxHeight: space(6),
  },
  fadeOutButton: {
    opacity: 0,
    transitionDuration: '0.5s',
    transitionProperty: 'opacity',
  },
  button: {
    insetInlineStart: 0,
    visibility: 'visible',
    opacity: 1,
    transitionDuration: '0s, 0.5s',
    transitionProperty: 'inset-inline-start, opacity, visibility',
  },
  buttonUnsubscribeVariant: {
    ...merge(
      mq({
        until: 'xl',
        value: {
          paddingInlineStart: space(4),
          paddingInlineEnd: space(4),
          paddingBottom: space(1),
          paddingTop: space(1),
          ...typesetter(-1, { lines: 4 }),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  footer: {
    position: 'relative',
  },
});

type BtnStateType = ButtonState['state'];
type BtnVariantVariant = Extract<ButtonProps['variant'], 'brand' | 'danger'>;
type BtnTextType = 'signup' | 'unsubscribe';

type Frequency = 'daily' | 'weekly' | 'monthly' | 'twiceAWeek';
type CardPaywallType = 'Premium' | '';
type kickerTextsKey = `${Frequency}${CardPaywallType}`;

const btnTextOptions: Record<BtnTextType, string> = {
  signup: fork({ default: 'הרשמה', hdc: 'Sign up' }),
  unsubscribe: fork({ default: 'להסרה', hdc: 'Unsubscribe' }),
};

const kickerTexts: Record<kickerTextsKey, string> = {
  daily: fork({
    default: 'דיוור יומי',
    hdc: 'Daily Newsletter',
  }),
  weekly: fork({
    default: 'דיוור שבועי',
    hdc: 'Weekly Newsletter',
  }),
  twiceAWeek: fork({
    default: 'דיוור דו-שבועי',
    hdc: 'Biweekly Newsletter',
  }),
  monthly: fork({
    default: 'דיוור שבועי',
    hdc: 'Weekly Newsletter',
  }),
  dailyPremium: fork({
    default: 'דיוור יומי למנויים בלבד',
    hdc: 'Subscribers only',
  }),
  weeklyPremium: fork({
    default: 'דיוור שבועי למנויים בלבד',
    hdc: 'Subscribers only',
  }),
  twiceAWeekPremium: fork({
    default: 'דיוור דו-שבועי למנויים בלבד',
    hdc: 'Subscribers only',
  }),
  monthlyPremium: fork({
    default: 'דיוור חודשי למנויים בלבד',
    hdc: 'Subscribers only',
  }),
} as const;

export type NewsletterCardProps = {
  cardData: UserRecommendation;
  email?: string;
} & (
  | ({
      variant: Extract<NewsletterRecommendationListVariant, 'thankYouPage'>;
    } & (
      | {
          biData?: never;
          getBiData: () => BiDataOverrides;
        }
      /** Bi data passed as an argument to biAction */
      | { biData: BiDataOverrides; getBiData?: never }
    ))
  | {
      variant: Extract<NewsletterRecommendationListVariant, 'unsubscribePage'>;
    }
);
const thankYouPageWidths = [56, 68, 112];
const unsubscribePageWidths = [72];
const thankYouPageSizes: React.ComponentProps<typeof HtzImage>['sizes'] = [
  { from: 'xxl', size: '68px' },
  { from: 'l', size: '65px' },
  { size: '56px' },
];
const unsubscribePageSizes: React.ComponentProps<typeof HtzImage>['sizes'] = [{ size: '72px' }];

export default function NewsletterCard({ cardData, email, ...props }: NewsletterCardProps) {
  const isThankYouPage = props.variant === 'thankYouPage';
  const isUnsubscribePage = props.variant === 'unsubscribePage';

  // on success
  const [success, setSuccess] = React.useState<boolean>(false);
  // animations
  const [subscriptionTransition, setSubscriptionTransition] = React.useState<boolean>(false);
  const [fadeOutButton, setFadeOutButton] = React.useState<boolean>(false);
  const [startAnimation, setStartAnimation] = React.useState<boolean>(false);
  // btn states
  const [btnState, setBtnState] = React.useState<BtnStateType>('auto');
  const [btnBusyNotice, setBtnBusyNotice] = React.useState<string>('');
  const [btnVariant, setBtnVariant] = React.useState<BtnVariantVariant>('brand');
  const [btnText, setBtnText] = React.useState<string>(btnTextOptions.signup);
  // form-field description
  const [msg, setMsg] = React.useState<string | undefined>(undefined);
  const [displayMessage, setDisplayMessage] = React.useState<boolean>(false);
  const [invalid, setInvalid] = React.useState<boolean>(false);
  const formfieldId = React.useId();
  const newsletterCardRef = React.useRef<HTMLElement>(null);
  const biAction = useBi();

  const newsletterSignup = useNewsletterSignupPersonal();
  const newsletterUnsubscribe = useUserAlertsRegistration();

  const { image, segmentName, subscribersOnly, frequency, description, site, tagNumber } = cardData;

  const kickerTextKey = frequency
    ? (((frequency as Frequency | null) +
        (subscribersOnly ? 'Premium' : ('' as CardPaywallType))) as kickerTextsKey)
    : null;

  const kickerText = kickerTextKey ? kickerTexts[kickerTextKey] : null;

  const setSubscribeBtn = () => {
    setBtnVariant('brand');
    setBtnText(btnTextOptions.signup);
  };

  const setUnsubscribeBtn = () => {
    setBtnVariant('danger');
    setBtnText(btnTextOptions.unsubscribe);
  };

  const clearBtnBusyState = () => {
    setBtnBusyNotice('');
    setBtnState('auto');
  };

  const biData = React.useMemo(() => {
    return isThankYouPage
      ? typeof props.getBiData === 'function'
        ? props.getBiData()
        : props.biData
      : {};
  }, [isThankYouPage, props]);

  useImpressionObserver({
    elementRef: newsletterCardRef,
    biData: {
      ...biData,
      feature: 'Promotions-Newsletter',
      feature_type: 'Content',
      personal_feature: true,
      ...(tagNumber ? { newsletter_segment_id: tagNumber } : {}),
      ...(segmentName ? { newsletter_segment_name: segmentName } : {}),
    },
  });

  const handleOnClick = React.useCallback(
    async (_: React.MouseEvent<HTMLButtonElement>) => {
      const action =
        btnVariant === 'brand' && btnText === btnTextOptions.signup
          ? 'subscribe'
          : btnVariant === 'danger' && btnText === btnTextOptions.unsubscribe
            ? 'unsubscribe'
            : undefined;

      if (action === 'subscribe') {
        setBtnState('busy');
        setBtnBusyNotice(
          fork({
            default: 'מעדכן הרשמה לדיוור...',
            hdc: 'Subscribing to newsletter...',
          })
        );
        const isUnsubscribe = false;
        const data = await newsletterSignup.mutateAsync({
          segmentName,
          isUnsubscribe,
          resourceId: tagNumber,
          site,
        });

        const { status } = data;
        // Handle errors
        if (status === 'error') {
          clearBtnBusyState();
          setSubscribeBtn();
          setInvalid(true);
          setDisplayMessage(true);
          setMsg(
            fork({
              default: 'אירעה שגיאה, אנא נסו מאוחר יותר',
              hdc: 'Something went wrong, please try again later',
            })
          );
        }
        // Success
        else if (status === 'success') {
          setSubscriptionTransition(true);
          clearBtnBusyState();
          setMsg('');
        }
        // Pending
        else {
          setSubscriptionTransition(true);
          clearBtnBusyState();
          setMsg(
            fork({
              default: 'להשלמת ההרשמה עליך לאמת את כתובת המייל',
              hdc: 'To complete the subscription, verify your email.',
            })
          );
        }
      }

      // on unsubscribe
      if (action === 'unsubscribe') {
        setMsg('');
        setInvalid(false);
        setBtnState('busy');
        setBtnBusyNotice(
          fork({ default: 'מסיר הרשמה לדיוור...', hdc: 'Unsubscribing from newsletter...' })
        );
        const resourceId = tagNumber;
        const resourceName = segmentName;
        const isUnsubscribe = true;
        const data = await newsletterUnsubscribe.mutateAsync({
          isUnsubscribe,
          site,
          resourceType: 'newsletter',
          resourceId,
          resourceName,
        });
        const { status } = data;

        if (status === 'success') {
          setFadeOutButton(true);
          clearBtnBusyState();
        } else {
          clearBtnBusyState();
          setInvalid(true);
          setMsg(
            fork({
              default: 'אירעה שגיאה, אנא נסו מאוחר יותר',
              hdc: 'Something went wrong, please try again later',
            })
          );
        }
      }

      biData &&
        biAction({
          ...biData,
          action_id: action === 'subscribe' ? 9 : 8,
          feature: 'Promotions-Newsletter',
          feature_type: 'Content',
          personal_feature: true,
          ...(tagNumber ? { newsletter_segment_id: tagNumber } : {}),
          ...(segmentName ? { newsletter_segment_name: segmentName } : {}),
          ...(email ? { newsletter_email: email } : {}),
        });
    },
    [
      biAction,
      biData,
      btnText,
      btnVariant,
      email,
      newsletterSignup,
      newsletterUnsubscribe,
      segmentName,
      site,
      tagNumber,
    ]
  );

  const BtnStateAndNotice =
    btnState === 'busy'
      ? {
          state: btnState,
          busyNotice: btnBusyNotice,
        }
      : {
          state: btnState,
          busyNotice: undefined,
        };

  const onTransitionEnd = () => {
    if (fadeOutButton) {
      setDisplayMessage(false);
      setSubscribeBtn();
      setStartAnimation(false);
      setFadeOutButton(false);
    }
    if (subscriptionTransition) {
      setSuccess(true);
      setStartAnimation(true);
      setDisplayMessage(true);
    }
  };

  const onAnimationEnd = () => {
    setUnsubscribeBtn();
    setSubscriptionTransition(false);
    setFadeOutButton(false);
    setSuccess(false);
  };

  if (!cardData) return null;
  const imgData = image?.files?.[0];
  return (
    <Card
      styleExtend={[
        c.base,
        isThankYouPage && c.baseThankYouVariant,
        isUnsubscribePage && c.baseUnsubscribeVariant,
      ]}
      as="article"
      aria-describedby={msg}
      ref={newsletterCardRef}
      data-testid="newsletter-card"
    >
      <header className={s9(c.header, isUnsubscribePage && c.headerUnsubscribeVariant)}>
        {image && imgData ? (
          <HtzImage
            caption={image.caption}
            credit={image.credit}
            type={image.type}
            photographer={image.photographer}
            contentId={image.contentId || ''}
            url={image.url}
            alt={image.alt || ''}
            imgData={imgData}
            aspect="square"
            widths={isThankYouPage ? thankYouPageWidths : unsubscribePageWidths}
            sizes={isThankYouPage ? thankYouPageSizes : unsubscribePageSizes}
            styleExtend={[
              c.img,
              isThankYouPage && c.imgThankYouVariant,
              isUnsubscribePage && c.imgUnsubscribeVariant,
            ]}
            data-testid="newsletter-card-img"
          />
        ) : null}
        <div
          className={s9(c.titlesWrapper, isUnsubscribePage && c.titlesWrapperUnsubscribeVariant)}
        >
          {kickerText ? (
            <p
              className={s9(
                c.mailingType,
                isThankYouPage && c.mailingTypeThankYouVariant,
                isUnsubscribePage && c.mailingTypeUnsubscribeVariant,
                subscribersOnly && c.premiumMailing
              )}
              data-testid="newsletter-card-kicker"
            >
              {kickerText}
            </p>
          ) : null}
          <h3
            className={s9(
              isThankYouPage && c.subjectTitleThankYouVariant,
              isUnsubscribePage && c.subjectTitleUnsubscribeVariant
            )}
            data-testid="newsletter-card-segment"
          >
            {segmentName}
          </h3>
        </div>
      </header>
      <p
        className={s9(
          c.description,
          isThankYouPage && c.descriptionThankYouVariant,
          isUnsubscribePage && c.descriptionUnsubscribeVariant
        )}
        data-testid="newsletter-card-description"
      >
        {description}
      </p>
      <footer>
        {success ? (
          <div
            className={s9(c.successDiv, startAnimation && c.slideAnimationInSuccess)}
            onAnimationEnd={onAnimationEnd}
          >
            <Icon icon="check" variant="success" styleExtend={[c.icon]} />
            <p>
              {fork({
                default: 'נרשמת בהצלחה',
                hdc: "Success! You're now subscribed",
              })}
            </p>
          </div>
        ) : null}
        <div>
          <Button
            onClick={handleOnClick}
            {...BtnStateAndNotice}
            size="small"
            variant={btnVariant}
            priority="secondary"
            styleExtend={[
              c.button,
              isUnsubscribePage && c.buttonUnsubscribeVariant,
              subscriptionTransition && c.slideOutTransitionButton,
              fadeOutButton && c.fadeOutButton,
            ]}
            onTransitionEnd={onTransitionEnd}
            data-testid="newsletter-card-btn"
          >
            {btnText}
          </Button>
          {msg ? (
            <FormfieldDescription
              id={formfieldId}
              isInvalid={invalid}
              styleExtend={[c.formfield, displayMessage && c.formfieldTransition]}
              data-testid="newsletter-card-msg"
            >
              {msg}
            </FormfieldDescription>
          ) : null}
        </div>
      </footer>
    </Card>
  );
}
